@import url('https://rsms.me/inter/inter.css');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif;
  }
}