.main {
    display: grid;
    grid-template-columns: 1fr max-content;
    height: 100vh;
}

.field {
    height: 100%;
    overflow: auto;
}

.playTable {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    align-items: center;
    height: 100%;
}

.selectedCards {
    padding: 60px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.selectedCards > div {
    margin-top: -80px;
}

.selectedCards > div:first-child {
    margin-top: 0;
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
    .main {
        grid-template-columns: none;
        grid-template-rows: 1fr max-content;
    }

    .selectedCards {
        flex-direction: row;
        padding: 20px;
    }

    .selectedCards > div {
        margin-top: 0;
        margin-left: -30px;
    }

    .selectedCards > div:first-child {
        margin-left: 0;
    }
}
