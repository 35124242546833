.cards {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    min-height: 300px;
    font-size: 22px;
    box-shadow: 1px 1px 8px black;
    overflow: auto;
    position: relative;
    cursor: pointer;
}

.item:hover {
    box-shadow: 1px 1px 12px black;
}

.--white {
    color: white;
}

.badge {
    position: absolute;
    top: 6px;
    right: 10px;
}

.header {
    padding: 20px 20px 0;
    font-size: 24px;
    text-align: center;
}

.headerBody {
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    background-color: white;
    display: inline-block;
    font-weight: bold;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: auto;
}

.contentBlock {
    margin: 30px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    background-color: white;
}

.contentTitle {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}

.contentItems {
    font-size: 20px;
    margin-top: 10px;
}

:global .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
}

.img {
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    margin: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 300px;
}

.img.red {
    background-image: url('../../assets/7.jpg');
}

.img.green {
    background-image: url('../../assets/10.jpg');
}

.img.gray {
    background-image: url('../../assets/8.jpg');
}

.img.white {
    background-image: url('../../assets/6.jpg');
}

.img.blue {
    background-image: url('../../assets/2.jpg');
}

.img.violet {
    background-image: url('../../assets/3.jpg');
}

.img.yellow {
    background-image: url('../../assets/4.jpg');
}

.img.rose {
    background-image: url('../../assets/9.jpg');
}

.img.orange {
    background-image: url('../../assets/5.jpg');
}

.img.turquoise {
    background-image: url('../../assets/1.jpg');
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
    .content {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 1fr;
    }
}
