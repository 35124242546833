@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif;
  }
}
.app_app__18ml0 {
  background-color: #0B71D9;
}

.app_resultBlock__2jl6D {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.app_resultContainer__1kaa2 {
  display: grid;
  grid-gap: 30px;
  width: 950px;
}

.app_resultTitle__DKBln {
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}

.app_resultImg__3rls2 {
  border-radius: 20px;
  box-shadow: 1px 1px 8px black;
  width: 480px;
  height: 315px;
  background-size: cover;
  background-image: url("/static/media/2 этап.b24600cb.jpg");
  margin: 0 auto;
}

.app_nextBtn__SMjHP {
  margin: 0 auto;
}

.app_resultHeader__1U0BV {
  display: flex;
}

.app_rabbit__-zaIb {
  width: 234px;
  height: 230px;
  background-size: cover;
  background-image: url(/static/media/rabbit.b4113111.png);
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
  .app_resultContainer__1kaa2 {
    width: auto;
  }

  .app_resultTitle__DKBln {
    padding-top: 0;
  }

  .app_resultHeader__1U0BV {
    flex-direction: column;
    align-items: center;
  }
}

.play-table_main__lmrGH {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    height: 100vh;
}

.play-table_field__31s6G {
    height: 100%;
    overflow: auto;
}

.play-table_playTable__2u1Ty {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    align-items: center;
    height: 100%;
}

.play-table_selectedCards__2OTB8 {
    padding: 60px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.play-table_selectedCards__2OTB8 > div {
    margin-top: -80px;
}

.play-table_selectedCards__2OTB8 > div:first-child {
    margin-top: 0;
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
    .play-table_main__lmrGH {
        grid-template-columns: none;
        grid-template-rows: 1fr -webkit-max-content;
        grid-template-rows: 1fr max-content;
    }

    .play-table_selectedCards__2OTB8 {
        flex-direction: row;
        padding: 20px;
    }

    .play-table_selectedCards__2OTB8 > div {
        margin-top: 0;
        margin-left: -30px;
    }

    .play-table_selectedCards__2OTB8 > div:first-child {
        margin-left: 0;
    }
}

.card_item__eTZds {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 1px 8px black;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
}

.card_item__eTZds.card_--white__E6GpS {
    color: white;
}

.card_--clickable__2A_nC {
    cursor: pointer;
}

.card_--clickable__2A_nC:hover {
    box-shadow: 1px 1px 12px black;
}

.card_item__eTZds.card_--usual__1edC1 {
    width: 300px;
    height: 400px;
    margin: 0 auto;
}

.card_item__eTZds.card_--small__3Z5-T {
    width: 50px;
    height: 80px;
    padding: 5px;
    overflow: hidden;
    word-break: break-all;
    box-sizing: content-box;
    box-shadow: none;
}

.card_item__eTZds.card_--small__3Z5-T .card_name__3qwOU {
    font-size: 12px;
}

.card_name__3qwOU {
    font-size: 22px;
    text-align: center;
}

.card_description__3Lddi {
    font-size: 14px;
    margin-top: 20px;
    text-align: start;
}

.card_img__28On- {
    margin-top: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
}
.final-result_cards__zfbsz {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.final-result_item__3eQMl {
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    min-height: 300px;
    font-size: 22px;
    box-shadow: 1px 1px 8px black;
    overflow: auto;
    position: relative;
    cursor: pointer;
}

.final-result_item__3eQMl:hover {
    box-shadow: 1px 1px 12px black;
}

.final-result_--white__20bti {
    color: white;
}

.final-result_badge__3x2xS {
    position: absolute;
    top: 6px;
    right: 10px;
}

.final-result_header__1okco {
    padding: 20px 20px 0;
    font-size: 24px;
    text-align: center;
}

.final-result_headerBody__25G2a {
    padding: 10px 30px;
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    background-color: white;
    display: inline-block;
    font-weight: bold;
}

.final-result_content__-QOIP {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: auto;
}

.final-result_contentBlock__2xR-B {
    margin: 30px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    background-color: white;
}

.final-result_contentTitle__17VzB {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}

.final-result_contentItems__3WTo7 {
    font-size: 20px;
    margin-top: 10px;
}

.ant-modal-content {
    border-radius: 15px;
    overflow: hidden;
}

.final-result_img__1O7J0 {
    border-radius: 20px;
    box-shadow: 1px 1px 8px black;
    margin: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 300px;
}

.final-result_img__1O7J0.final-result_red__1-UcK {
    background-image: url(/static/media/7.0c4f8749.jpg);
}

.final-result_img__1O7J0.final-result_green__2qaMK {
    background-image: url(/static/media/10.1019f322.jpg);
}

.final-result_img__1O7J0.final-result_gray__3bmTk {
    background-image: url(/static/media/8.2b8de62d.jpg);
}

.final-result_img__1O7J0.final-result_white__1q1dG {
    background-image: url(/static/media/6.88fdad49.jpg);
}

.final-result_img__1O7J0.final-result_blue__2RONt {
    background-image: url(/static/media/2.5cd1ab1d.jpg);
}

.final-result_img__1O7J0.final-result_violet__3MkSD {
    background-image: url(/static/media/3.9f7697a6.jpg);
}

.final-result_img__1O7J0.final-result_yellow__aarqR {
    background-image: url(/static/media/4.14a10d9d.jpg);
}

.final-result_img__1O7J0.final-result_rose__6r__Y {
    background-image: url(/static/media/9.f22c963d.jpg);
}

.final-result_img__1O7J0.final-result_orange__37VNv {
    background-image: url(/static/media/5.2dd31537.jpg);
}

.final-result_img__1O7J0.final-result_turquoise__2NZvf {
    background-image: url(/static/media/1.f4ff5b1b.jpg);
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
    .final-result_content__-QOIP {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

