.item {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 1px 8px black;
    user-select: none;
    overflow: hidden;
}

.item.--white {
    color: white;
}

.--clickable {
    cursor: pointer;
}

.--clickable:hover {
    box-shadow: 1px 1px 12px black;
}

.item.--usual {
    width: 300px;
    height: 400px;
    margin: 0 auto;
}

.item.--small {
    width: 50px;
    height: 80px;
    padding: 5px;
    overflow: hidden;
    word-break: break-all;
    box-sizing: content-box;
    box-shadow: none;
}

.item.--small .name {
    font-size: 12px;
}

.name {
    font-size: 22px;
    text-align: center;
}

.description {
    font-size: 14px;
    margin-top: 20px;
    text-align: start;
}

.img {
    margin-top: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
}