.app {
  background-color: #0B71D9;
}

.resultBlock {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
}

.resultContainer {
  display: grid;
  grid-gap: 30px;
  width: 950px;
}

.resultTitle {
  font-size: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}

.resultImg {
  border-radius: 20px;
  box-shadow: 1px 1px 8px black;
  width: 480px;
  height: 315px;
  background-size: cover;
  background-image: url('../assets/2 этап.jpg');
  margin: 0 auto;
}

.nextBtn {
  margin: 0 auto;
}

.resultHeader {
  display: flex;
}

.rabbit {
  width: 234px;
  height: 230px;
  background-size: cover;
  background-image: url('../assets/rabbit.png');
}

@media screen and (min-device-width: 320px) and (max-device-width: 1280px) {
  .resultContainer {
    width: auto;
  }

  .resultTitle {
    padding-top: 0;
  }

  .resultHeader {
    flex-direction: column;
    align-items: center;
  }
}
